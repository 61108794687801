import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, Chip } from '@material-ui/core';

import { i18ndb, rest, t } from '../services';
import { loadingIcon } from '../loadingIcon';
import { DELETE_ATTACHMENT_EVENT, FILE_UPLOADED_EVENT } from "../events/Gui";
import {formState} from "../forms/FormState";
import { FileComponent } from "../../../auto/js/widgets/FileComponent";
import { getServiceUri } from "../metadata";

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
}));

const FILE_TYPE_ICONS = {
	'jpg': 'file-image',
	'gif': 'file-image',
	'png': 'file-image',
	'bmp': 'file-image',
	'mov': 'file-video',
	'wav': 'file-audio',
	'mp3': 'file-audio',
	'xls': 'file-excel',
	'xlsx': 'file-excel',
	'doc': 'file-word',
	'docx': 'file-word',
	'ppt': 'file-powerpoint',
	'pptx': 'file-powerpoint',
	'csv': 'file-csv',
	'pdf': 'file-pdf'
}

const getFileExt = /(?:\.([^.]+))?$/;

function arrayRemove(arr, value) {
	return arr.filter(function(element) {
		return element.id != value;
	});
}

export const Attachment = (props) => {

	let ext = getFileExt.exec(props.attachment.fileName)[1];
	var icon;

	let onDeleteCallback = (id) => {
		var list = arrayRemove(props.list, id)
		formState.setAttachmentList(list);
		props.setData(list);
	}

	DELETE_ATTACHMENT_EVENT.subscribe(onDeleteCallback);

	if (ext)
		icon = FILE_TYPE_ICONS[ext];

	if (!icon)
		icon = 'file';

	return (
		<>
		<FileComponent data={props.attachment} readOnly={props.readOnly} onDownload={() => props.handleClick(props.attachment.id)} onDelete={() => props.handleDelete(props.attachment.id)} previewUrl={() => props.previewUrl(props.attachment.id)} token={props.token} updateFileData={props.updateFileData}/>
		</>
	);
}

/**
	Actually takes a map in input id -> fileName
*/
const AttachmentList = (props) => {
	const classes = useStyles();
	const items = [];
	if (props.list)
		for (const element of props.list)
			items.push(<Attachment key={element.id} {...props} attachment={element} />);

	return (
		<div className={classes.root}>
			{items}
		</div>
	);
}



export const AttachmentsArea = (props) => {

	const [data, setData] = useState(undefined);
	const [token, setToken] = useState(undefined);

	if (!data) {
		if (!token)
			rest.getToken(getServiceUri() + 'token/get-auth-code').then(token => setToken(token));
		props.loadData().then(
			response => {
			var attachmentsInfo = response.map(element => {
				return {id: element.id, fileNmae: element.fileName}
			})
			formState.setAttachmentList(attachmentsInfo);
			setData(response)
			});
		return loadingIcon;
	}

	let callback = (event) => {
		formState.addToAttachmentList({id: event.id, fileNmae: event.fileName});
		setData(() => {[...data, event]})
	}

	FILE_UPLOADED_EVENT.subscribe(callback);

	const items = [];
		items.push(<label style={{marginTop:'20px'}}>{props.label}</label>);
		items.push(<AttachmentList {...props} list={data} setData={setData} token={token}/>);
	return items;
}