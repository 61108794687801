import React from "react";
import _ from 'lodash';
import { getServiceUri, metadataLoader, pojoMetadata } from "../../../../auto/js/metadata";
import { rest } from "../../../../auto/js/services/RestClient";
import { t } from '../../../../auto/js/services/i18ndb';
import { showNotification } from "../../../../auto/js/utils";
import { dto2form, form2dto } from "../../../../main/js/forms/complaintMtlb/ComplaintMtlbFormCommon";

export const saveComplaintMtlbForm = async (formData) => {
	let data = _.clone(formData);
    let dto = pojoMetadata['complaint-mtlb'].form2dto(data);
    form2dto(formData, dto);
    try {
		return rest.request(getServiceUri() + 'complaint-mtlb/new-document', 'POST', dto)
    } catch (err) {
        alert(err);
    }
}

export const loadComplaintMtlbFormData = async (id) => {
	return await rest.read('complaint-mtlb', id).then(response => {
		let form = dto2form(response);
		let tagFilter = {and: true};
		tagFilter['complaint-mtlb-tag'] = {complaintMtlbId: id};
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error");
		return rest.search('complaint-mtlb-tag', tagFilter).then(tags => {
			form['tags'] = tags
			if (tags.status)
				showNotification(response.message.split('Detail: ')[1], "error");
			return form;
		})
	})
}

export const updatePendingComplaintMtlbForm = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['complaint-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'complaint-mtlb/pending/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated complaint-mtlb`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const updateComplaintMtlbForm = async (formData) => {
	let data = _.clone(formData);
	const dto = pojoMetadata['complaint-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'complaint-mtlb/edit-document', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Created complaint-mtlb`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const deleteComplaintMtlbForm = async (id) => {
    try {
        return rest.delete('complaint-mtlb', id).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Deleted complaint-mtlb`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const updateRejectedComplaintMtlbForm = async (formData) => {
	//TODO: Move form2dto from pojo metadata
	let data = _.clone(formData);
	let dto = pojoMetadata['complaint-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'complaint-mtlb/rejected/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated complaint-mtlb`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const rejectComplaintMtlbForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'complaint-mtlb/pending/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const investigateComplaint = async (formData) => {
	let dto = {}
	dto.id = formData.id
	return rest.request(getServiceUri() + 'apply/investigate-complaint', 'POST', dto);
}

export const closeComplaint = async (id) => {
	let dto = {}
	dto.id = id
	return rest.request(getServiceUri() + 'apply/close-complaint', 'POST', dto);
}

export const rejectReadyComplaintMtlbForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'complaint-mtlb/ready/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const approveReadyComplaintMtlbForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'complaint-mtlb/ready/approve', 'POST', id);
    } catch (err) {
        alert(err);
    }
}
