export const MtlbStatus = {
	"SUBMITTED": 1
	,
	"MANUAL_CHECK": 2
	,
	"ARCHIVED": 3
	,
	"CLOSED": 4
	,
	"INVESTIGATING": 5
	,
	"INITIATED": 6
	,
	"ASSIGNED": 7
	,
	"READY_FOR_APPROVAL": 8
	,
	"NOT_ASSIGNED": 9
	
}
Object.freeze(MtlbStatus);
const toTypeMap = {};
Object.keys(MtlbStatus).forEach(key => {
	toTypeMap[MtlbStatus[key]] = key;
});
export function getMtlbStatus (value) {
	return toTypeMap[value];
}

export function getAllMtlbStatus () {
    return Object.keys(MtlbStatus);
}

export const MTLB_STATUS_SUBMITTED = "SUBMITTED";
export const MTLB_STATUS_MANUAL_CHECK = "MANUAL_CHECK";
export const MTLB_STATUS_ARCHIVED = "ARCHIVED";
export const MTLB_STATUS_CLOSED = "CLOSED";
export const MTLB_STATUS_INVESTIGATING = "INVESTIGATING";
export const MTLB_STATUS_INITIATED = "INITIATED";
export const MTLB_STATUS_ASSIGNED = "ASSIGNED";
export const MTLB_STATUS_READY_FOR_APPROVAL = "READY_FOR_APPROVAL";
export const MTLB_STATUS_NOT_ASSIGNED = "NOT_ASSIGNED";
