import React from "react";

import { MTLB_TYPE_COMPLAINT } from "../../../../auto/js/metadata/MtlbType";
import { INCIDENT_CATEGORY_GOVERNMENTAL_CONCERN, IncidentCategory } from "../../../../auto/js/metadata/IncidentCategory";
import { COMPLAINANT_CONTACT_PREFERENCES_EMAIL, ComplainantContactPreferences } from "../../../../auto/js/metadata/ComplainantContactPreferences";
import { buildEmptyObject } from "../../../../auto/js/widgets/FormComponent";
import { GeoDataComponent } from "../../../../auto/js/widgets/GeoDataComponent";
import { getServiceUri } from "../../../../auto/js/metadata";
import { rest } from "../../../../auto/js/services";
import { DELETE_ATTACHMENT_EVENT } from "../../../../auto/js/events/Gui";

const incidentCategory = {
	1: "CONSUMER_RIGHTS",
	2: "ENVIRONMENTAL_CONCERNS",
	3: "PUBLIC_SAFETY",
	4: "GOVERNMENTAL_CONCERN"
}

const complainantContactPreferences = {
	1: "EMAIL",
	2: "PHONE"
}

export const complaintMtlbFields = [
	{name: "id", type: "number", x: 1, y: 1, layout: "col-md-3" },
	{name: "legacyId", type: "text", x: 1, y: 2, layout: "col-md-3" },
	{name: "complainantFirstName", type: "text", x: 1, y: 4, layout: "col-md-6" },
	{name: "complainantLastName", type: "text", x:1, y:5, layout:"col-md-6"},
	{name: "complainantIdCardNumber", type: "number", x:2, y:5, layout:"col-md-6"},
	{name: "complainantEmailAddress", type: "text", x:1, y: 6, layout:"col-md-6"},
	{name: "complainantPhoneNumber", type: "text", x:1, y: 6, layout:"col-md-6"},
	{name:"addressBox", label: "complainantAddress", components: [
		{name: "complainantAddress", type: "custom", x:1, y:1, layout:"col-md-12", component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled} default={"48"}/>}
	 ], type: "box", x:1, y:7, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"},
	{name: "complainantContactPreferences", type: "select", x:1, y: 8, layout:"col-md-6", metadata: () => complainantContactPreferences},
	{name: "incidentCategory", type: "select", x:1, y: 9, layout:"col-md-6",  metadata: () => incidentCategory},
    { name: "incidentDate", type: "date", x:1, y:10,  layout:"col-md-6"},
	{ name: "incidentTime", type: "time", x:2, y:10,  layout:"col-md-6" },
	{name: "jurisdiction", type:"text", x:1, y:17, layout:"col-md-6"},
    {name: "subjectOfComplaint", type:"text", x:1, y:17, layout:"col-md-6"},
    {name: "subjectAddress", type:"text", x:1, y:17, layout:"col-md-6"},
    {name: "description", type:"text", x:1, y:17, layout:"col-md-6"},
	{name: "attachments", type:"file", x:1, y:18, layout:"col-md-12",
		uploadUrl: (id) => getUploadUrl(id), 
		previewUrl: async (id) => getPreviewUrl(id),
		loadData: async (id) => loadAttachment(id), 
		handleDelete:(id) => handleDelete(id), 
		handleClick: (id) => handleClick(id),
		updateFileData: (data) => updateFileData(data)
	},
];

const getUploadUrl = (id) => {
	return getServiceUri() + 'complaint-mtlb/attachments' + '/' + id;
}

const getPreviewUrl =  async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	return getServiceUri() + 'complaint-mtlb/attachments' + '/preview/' + id + '/' + token;
}

const updateFileData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getServiceUri() + 'complaint-mtlb/attachments' + '/' + data.id, "PUT", filter);
}

const handleClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'complaint-mtlb/attachments' + '/' + id + '/' + token;
};
const handleDelete = (id) => {
	rest.delete('complaint-mtlb/attachments', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};

const loadAttachment = async (id) => {
	let filter = {and: true};
	filter['complaint-mtlb-attachments'] = {};
	filter['complaint-mtlb-attachments']['complaintMtlbId'] = id;
	return rest.search('complaint-mtlb/attachments', filter)
}

export const form2dto = (formData, dto) => {
	if (dto.mtlbType === null || !dto.mtlbType)
		dto.mtlbType = MTLB_TYPE_COMPLAINT;
	if (dto.incidentCategory === null || !dto.incidentCategory)
		dto.incidentCategory = INCIDENT_CATEGORY_GOVERNMENTAL_CONCERN;
	else
		dto.incidentCategory = incidentCategory[dto.incidentCategory];
	if (dto.complainantContactPreferences === null || !dto.complainantContactPreferences)
		dto.complainantContactPreferences = COMPLAINANT_CONTACT_PREFERENCES_EMAIL;
	else
		dto.complainantContactPreferences = complainantContactPreferences[dto.complainantContactPreferences]
	if (dto.complainantIdCardNumber === null || ! dto.complainantIdCardNumber)
		dto.complainantIdCardNumber = 0;

	if (formData.incidentTime != null && typeof(formData.incidentTime) != 'string') {
		const date = new Date(formData.incidentTime)
		let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
		let minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
		dto.incidentTime = hour + ":" + minute;
	}
}

export const dto2form = (dto) => {
	let form = dto;
	if (form.complainantIdCardNumber == 0)
		form.complainantIdCardNumber = null
	if (dto.incidentTime !== null) {
		dto.incidentTime = new Date().setHours(dto.incidentTime[0], dto.incidentTime[1]);
	}
	if (dto.incidentCategory !== null)
		form.incidentCategory = IncidentCategory[dto.incidentCategory];
	if (dto.complainantContactPreferences !== null)
		form.complainantContactPreferences = ComplainantContactPreferences[dto.complainantContactPreferences];
  return form;
}

export const buildComplaintMtlbFormEmptyObject  = () => {
	return buildEmptyObject(complaintMtlbFields);
}

export const newComplaintMtlbFormForm2Dto = (form, dto) => {
	
}