import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from '@material-ui/icons/Visibility';


import { grey } from "@mui/material/colors";
import { displayReadAvocatsForm } from "../../../../auto/js/forms/avocats/NewAvocatsForm";
import { displayAvocatsList } from "../../../../auto/js/lists/avocats/AvocatsList";


export const getOrderBy = () => {
    return "ID"
}

export const getRowActions = (rowData) => {
    return (
        <Tooltip>
            <IconButton 
                onClick={(event) => {
                    rowData.gui.goTo(displayReadAvocatsForm(() => (displayAvocatsList(rowData.gui))), rowData.id)
                }}
                size="small"
                sx={{color: grey[900]}}
            >
                <VisibilityIcon />
            </IconButton>
        </Tooltip>
    )
}