import {displayNewTranslationsForm} from '../../../auto/js/forms/metadata/TranslationsForm'
import {displayTranslationsList} from '../../../auto/js/lists/metadata/TranslationsList'
import {whoami} from '../../../auto/js/users/UserInfo';
import { RIBBON_MENU } from '../../../auto/js/metadata/MenuType';

import { displayComplaintMtlbList } from '../../../auto/js/lists/complaintMtlb/ComplaintMtlbList'
import { displayNewAvocatsForm } from '../../../auto/js/forms/avocats/NewAvocatsForm';
import { displayAvocatsList } from '../../../auto/js/lists/avocats/AvocatsList';

export const menu = () => {
    return {
        ...((whoami().roles.includes('ALL')|| whoami().roles.includes('REGISTRAR')) && {
			"avocats": {
				submenu: {
					"a": {
						options: {
                            new: { label: "Nouveau", do: (gui) => displayNewAvocatsForm(gui)},
							applications: { label: "Avocats", do: (gui) => displayAvocatsList(gui)}
						},
						label: "Avocats"
					}
				},
				label: "Plaintes"
			}
        }),
        ...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
            "crvs-admin": {
                submenu: {
                    "translations": {
                        options: {
                            list: {label: "Translations", do: displayTranslationsList},
                            new: {label: "New", do: displayNewTranslationsForm},
                        },
                        label: "Translations"
                    },
                },
                label: "Configuration"
            }
        }),
        /* ...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
            "geography": {
                submenu: {
                    "locations": {
                        options: {
                            list: {label: "Geo Data", do: displayLocationsList},
                            new: {label: "New", do: displayNewLocationForm},
                        },
                        label: "Geo Data"
                    },
                },
                label: "Geography"
            }

        }) */
    }
}
export const menuType = RIBBON_MENU;
